import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Contact = ({ data, location }) => {


    const siteTitle = data.site.siteMetadata?.title || `Title`

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="ツール" />
        <div className="post-header">
            <h1>ツール</h1>
        </div>
        <div className="post-body">
            <h4>ポート解放確認</h4>
            <p><Link to={`/tools/portcheck`} >ポート解放確認</Link></p>
            <p>ポート解放確認にお使いください。</p>
            <h4> IPv6（MAP-E方式）使用可能ポート確認</h4>
            <p> <Link to={`/tools/portcheck-map-e`} >IPv6（MAP-E方式）使用可能ポート確認</Link></p>
            <p>NTT東西のフレッツ網を使ったIPv4 over IPv6接続サービス
              （v6プラス、v6アルファ、IPv6オプション、v6エクスプレス等）の使用可能ポート確認にお使いください</p>

        </div>
        </Layout>
    )
}

export default Contact

export const contactQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
